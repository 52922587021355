import React from "react"
import {
  BannerWrapper,
  BannerContents,
  BannerContentLeft,
  BannerContentRight,
  BannerBtn,
  BannerPara,
  BannerH3,
} from "./banner.style"
import { Container } from "@bootstrap-styled/v4"
import Fade from "react-reveal/Fade"
import { useStaticQuery, graphql } from "gatsby"
import GatsImg from "gatsby-image"

const Banner = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      corporateJson {
        Banner {
          BannerBtn
          BannerH3
          BannerImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          DelayConstant
          BannerPara
        }
      }
    }
  `)

  return (
    <BannerWrapper id="homeContainer">
      <Container>
        <BannerContents>
          <BannerContentLeft>
            <Fade
              bottom
              delay={1 * JSONData.corporateJson.Banner.DelayConstant}
            >
              <div>
                <BannerH3>{JSONData.corporateJson.Banner.BannerH3}</BannerH3>
                <BannerPara>
                  {JSONData.corporateJson.Banner.BannerPara}
                </BannerPara>
                <BannerBtn href="#subscribeContainer">
                  {JSONData.corporateJson.Banner.BannerBtn}
                </BannerBtn>
              </div>
            </Fade>
          </BannerContentLeft>

          <BannerContentRight>
            <Fade
              bottom
              delay={1 * JSONData.corporateJson.Banner.DelayConstant}
            >
              <GatsImg
                fluid={
                  JSONData.corporateJson.Banner.BannerImage.childImageSharp
                    .fluid
                }
                className="BannerImageHolder"
                imgStyle={{
                  maxHeight: "430px",
                  width: "auto",
                  left: "0px",
                  right: "0px",
                  margin: "0px auto",
                }}
                alt=""
              />
            </Fade>
          </BannerContentRight>
        </BannerContents>
      </Container>
    </BannerWrapper>
  )
}

export default Banner
