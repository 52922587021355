import React from "react"
import { Container, Row, Col } from "@bootstrap-styled/v4"
import {
  FooterWrapper,
  FooterCol,
  FooterTitle,
  FooterPara,
  FooterUlWrapper,
  FooterLi,
  FooterSocial,
  FbIcon,
  TwitterIcon,
  LinkedinIcon,
} from "./footer.style"
import Fade from "react-reveal/Fade"

import ScrollSpy from "react-scrollspy"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { useStaticQuery, graphql } from "gatsby"

const Footer = () => {
  const JSONData = useStaticQuery(graphql`
    query {
      corporateJson {
        Footer {
          DelayConstant
          FooterCol1 {
            FooterTitle
            FooterPara {
              Para
            }
          }
          FooterCol2 {
            FooterTitle
            FooterPara {
              Para
            }
          }
          FooterCol3 {
            FooterTitle
            Items
            FooterUl {
              Menu
              Href
            }
          }
          FooterCol4 {
            FooterTitle
            FooterSocial {
              FooterSocialImg
            }
          }
          BottomFooterPara
          BottomFooterName
          BottomFooterLink
        }
      }
    }
  `)
  return (
    <div>
      <FooterWrapper>
        <Container>
          <Fade Bottom delay={JSONData.corporateJson.Footer.DelayConstant}>
            <Row>
              <Col md="6">
                <FooterCol>
                  <FooterTitle>
                    {JSONData.corporateJson.Footer.FooterCol2.FooterTitle}
                  </FooterTitle>
                  {JSONData.corporateJson.Footer.FooterCol2.FooterPara.map(
                    (item, idx) => {
                      return <FooterPara>{item.Para}</FooterPara>
                    }
                  )}
                </FooterCol>
              </Col>
              <Col md="3">
                <FooterCol>
                  <FooterTitle>
                    {JSONData.corporateJson.Footer.FooterCol3.FooterTitle}
                  </FooterTitle>
                  <FooterUlWrapper>
                    <ScrollSpy
                      offset={-59}
                      items={JSONData.corporateJson.Footer.Items}
                      currentClassName="is-current"
                    >
                      {JSONData.corporateJson.Footer.FooterCol3.FooterUl.map(
                        (item, idx) => {
                          return (
                            <FooterLi>
                              <AnchorLink offset={55} href={item.Href}>
                                {item.Menu}
                              </AnchorLink>
                            </FooterLi>
                          )
                        }
                      )}
                    </ScrollSpy>
                  </FooterUlWrapper>
                </FooterCol>
              </Col>
              <Col md="3">
                <FooterCol>
                  {/* <FooterTitle>{JSONData.corporateJson.Footer.FooterCol4.FooterTitle}</FooterTitle> */}
                  <FooterTitle>
                    {JSONData.corporateJson.Footer.FooterCol1.FooterTitle}
                  </FooterTitle>
                  {JSONData.corporateJson.Footer.FooterCol1.FooterPara.map(
                    (item, idx) => {
                      return <FooterPara>{item.Para}</FooterPara>
                    }
                  )}
                  <FooterSocial
                    href="https://www.facebook.com/dhamirainc/"
                    target="_blank"
                    aria-label="Facebook Link"
                  >
                    <FbIcon />
                  </FooterSocial>
                  <FooterSocial
                    href="https://twitter.com/dhamirainc"
                    target="_blank"
                    aria-label="Twitter Link"
                  >
                    <TwitterIcon />
                  </FooterSocial>
                  <FooterSocial
                    href="https://www.linkedin.com/company/dhamira"
                    target="_blank"
                    aria-label="LinkedIn Link"
                  >
                    <LinkedinIcon />
                  </FooterSocial>
                </FooterCol>
              </Col>
            </Row>
          </Fade>
        </Container>
      </FooterWrapper>
    </div>
  )
}

export default Footer
