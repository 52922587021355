import React, { Component } from "react"
import { Container, Row, Col } from "@bootstrap-styled/v4"
import {
  NewsletterSection,
  NewsletterInner,
  NewsletterForm,
  NewsletterInput,
  SubscribeSpanErr,
  SubscribeSpanSuccess,
  Form,
  FormGroup,
  SendIcon,
  NewsletterSubmit,
} from "./subscribeletter.style"
import { SectionHeading } from "../Common/common.style"
import Fade from "react-reveal/Fade"
import { StaticQuery, graphql } from "gatsby"

const WRNG_MSG_TIMEOUT = 3000

class NewsLetter extends Component {
  constructor() {
    super()
    this.state = {
      subscribe_email: "",
      errors: [],
    }
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }

  validateForm() {
    var error_flag = false
    let errors = {}
    if (this.state.subscribe_email === "") {
      error_flag = true
      errors["subscribe_email"] = "Please enter email!"
      setTimeout(
        function() {
          this.setState({ errors: {} })
        }.bind(this),
        WRNG_MSG_TIMEOUT
      )
    }

    if (this.state.subscribe_email !== "") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      )
      if (!pattern.test(this.state.subscribe_email)) {
        error_flag = true
        errors["subscribe_email"] = "Please enter valid email ID!"
        setTimeout(
          function() {
            this.setState({ errors: {} })
          }.bind(this),
          WRNG_MSG_TIMEOUT
        )
      }
    }
    this.setState({
      errors: errors,
    })
    return error_flag
  }

  subscribeSubmit = e => {
    e.preventDefault()
    if (!this.validateForm()) {
      //SUCCESS
      //TO DO
      console.log("Email: " + this.state.subscribe_email)
      document.getElementById("success_subscribe_msg").innerHTML =
        "Subscribed successfully."
      this.setState(
        {
          subscribe_email: "",
          errors: [],
        },
        () => {
          setTimeout(function() {
            document.getElementById("success_subscribe_msg").innerHTML = ""
          }, WRNG_MSG_TIMEOUT)
        }
      )
    }
  }

  render() {
    return (
      <NewsletterSection id="subscribeContainer">
        <Container>
          <Row>
            <Col md="12">
              <Fade
                bottom
                delay={
                  this.props.corporateJson.SubscribeNewsLetter.DelayConstant
                }
              >
                <SectionHeading>
                  {this.props.corporateJson.SubscribeNewsLetter.SectionHeading}
                </SectionHeading>
              </Fade>
            </Col>
          </Row>
          <NewsletterInner>
            <Form
              id="subscribeForm"
              name="Subscription Form"
              method="post"
              data-netlify="true"
              netlify-honeypot="bot-field"
            >
              <div className="field hidden" style={{ display: "none" }}>
                <label className="label">
                  Don't fill this out if you're human.
                  <div className="control">
                    <input className="input" name="bot-field" type="text" />
                  </div>
                </label>
              </div>

              <input type="hidden" name="form-name" value="Subscription Form" />
              <Fade
                bottom
                delay={
                  this.props.corporateJson.SubscribeNewsLetter.DelayConstant
                }
              >
                <FormGroup>
                  <NewsletterForm>
                    <NewsletterInput
                      placeholder="Enter Your E-Mail Here"
                      name="subscribe_email"
                      onChange={this.handleChange}
                      value={this.state.subscribe_email}
                    ></NewsletterInput>
                    <NewsletterSubmit type="submit">
                      Submit <SendIcon />
                    </NewsletterSubmit>
                  </NewsletterForm>
                </FormGroup>
              </Fade>
              <SubscribeSpanErr id="err_subscribe_email">
                {this.state.errors.subscribe_email}
              </SubscribeSpanErr>
              <SubscribeSpanSuccess id="success_subscribe_msg"></SubscribeSpanSuccess>
            </Form>
          </NewsletterInner>
        </Container>
      </NewsletterSection>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        corporateJson {
          SubscribeNewsLetter {
            DelayConstant
            SectionHeading
            SectionSubHeading
          }
        }
      }
    `}
    render={data => <NewsLetter corporateJson={data.corporateJson} />}
  />
)
